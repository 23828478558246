import https from "./https"
import store from "@/store"

const marketingConfigApi = {
  getMarketingConfigList (params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/marketing-config`, { params })
  },
  getMarketingConfig (marketingConfigId) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/marketing-config/${marketingConfigId}`)
  },
  updateMarketingConfig (params) {
    const organization = store.state.general.organization
    return https.put(`admin/organizations/${organization.id}/marketing-config/${params.id}`, params)
  },
  removeMarketingConfig (params) {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id + '/marketing-config/' + params.id;
    return https.delete(url)
  },
  storeMarketingConfig (params) {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id  + '/marketing-config'

    return https.post(url, params)
  },
}

export default marketingConfigApi
